<template>
  <div class="wrapper">
    <div class="page">
      <div id="loader"></div>
      <div class="page-inner" id="main_page">
        <!-- end test -->
        <div class="va-card">
          <div class="row">
            <div class="col-md-12">
              <div class="dash_card_body pb-0">
                <div class="header_section">
                  <h4 class="menu_header">Loyalty Activities</h4>

                  <div class="right-btnGroup" v-if="adminRoles.includes(user.role.id)">
                    <h1>Export Loyalty Activities</h1>
                    <div class="btn-toolbar">
                      <button class="btn btn-purple" @click="downloadExcel()"><span v-if="downloading"> <i class="fa fa-spinner fa-spin"></i></span> Excel</button>
                    </div>
                  </div>

                  
                </div>
                <div class="search_section">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group has-search mt-1">
                        <span class="fa fa-search form-control-feedback"></span>
                        <input type="text" v-model="search" @keyup="searchActivity()" aria-required="false" autocomplete="on" inputmode="text" name="search_input" placeholder="Search..." class="form-control">
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group has-search mt-1">
                        <button class="btn btn-orange search">Search</button>
                      </div>
                    </div>
                  </div>
                  <div class="row py-3">
                    <div class="col-md-3">
                      <div class="amount">
                        <span><b>Amount Earned:</b> &nbsp; {{currency+' '+ formatPrice(summary.amount_earned)}}</span>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="amount">
                        <span><b>Amount Redeemed:</b> &nbsp; {{currency+' '+ formatPrice(summary.amount_redeemed)}}</span>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="amount">
                        <span><b>Point Earned:</b> &nbsp; {{summary.total_points_earned}}</span>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="amount">
                        <span><b>Point Redeemed:</b> &nbsp;  {{summary.total_points_redeemed}}</span>
                      </div>
                    </div>
                  </div>
                    
                </div>
              </div>
              <hr>
            </div>
            <div class="col-lg-12">
              <div class="dash_card_body pt-0">
                <div class="table_section">
                  <table class="table  table-hover">
                    <thead>
                      <tr>
                        <th scope="col">Customer</th>
                        <th scope="col">Points Earned</th>
                        <th scope="col">Points Redeemed</th>
                        <th scope="col">Balance </th>
                        <th scope="col">Amount Earned</th>
                        <th scope="col">Amount Redeemed</th>
                        <!-- <th scope="col">Last Updated</th> -->
                      </tr>
                    </thead>
                    <tbody v-if="!loading && activities.length > 0">
                      <tr v-for="activity in activities" :key="activity.id">
                        <td>
                          <router-link :to="{ name: 'loyalty_activity_detail', params: { id:activity.id }}">
                            <span class="title_text">{{activity.phone_number}}</span>
                          </router-link>
                        </td>
                        <td>{{activity.total_point_earned}}</td>
                        <td>{{activity.total_point_redeemed}}</td>
                        <td>{{activity.points}}</td>
                        <td>{{currency+' '+ formatPrice(activity.total_amount_earned)}}</td>
                        <td>{{currency+' '+  formatPrice(activity.total_amount_redeemed)}}</td>
                        <!-- <td></td> -->
                      </tr>
                    </tbody>
                    <tbody v-if=" activities.length  == 0">
                      <tr>
                        <td colspan="7" style="text-align:center"> No Data</td>
                      </tr>
                    </tbody>
                    <tfoot v-if="!loading">
                      <tr>
                        <td colspan="8">
                          <div id="pagination" v-if="totalPages > 1">
                            <Pagination :total-pages="totalPages" :per-page="recordsPerPage" :current-page="page" @pagechanged="onPageChange" />
                            <ul class="showItems">
                              <li>Show Items:
                                <select @change="onChangeRecordsPerPage" v-model="recordsPerPage">
                                  <option :value="10">10</option>
                                  <option :value="20">20</option>
                                  <option :value="30">30</option>
                                  <option :value="40">40</option>
                                  <option :value="50">50</option>
                                </select>
                              </li>
      
                            </ul>
                          </div>
      
                        </td>
                      </tr>
                    </tfoot>
                  </table>
      
                </div>
              </div>
              <div v-if="loading" class="loading-cover">
                <div class="loader"> </div>
              </div>
      
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
      
      <script>
      import moment from 'moment';
      import ReusableFunctionMixins from '@/mixins/ReusableFunctionMixins';
      import ReusableDataMixins from '@/mixins/ReusableDataMixins';
      import Pagination from '@/pages/pagination/pagination.vue';
      export default {
          components: {
              Pagination,
          },
          data() {
              return {
                  downloading: false,
                  activities: [],
                  summary: [],
                  loading: false,
                  search:'',
                  page: 1,
                  start: '',
                  end: '',
                  totalPages: 0,
                  totalRecords: 0,
                  recordsPerPage: 10,
                  debounce: null,
                  menuspinner: "<i class='fa fa-spinner fa-spin '></i>",
                  currency:'KES'
              }
          },
          mounted: async function () {
              this.getLoyaltyActivities();
              this.currency = this.pesapalConfig.currency_code ?  this.pesapalConfig.currency_code : 'KES';
              // disable input if use is not admin or superadmin
      
          },
          methods: {
            searchActivity() {
                  clearTimeout(this.debounce);
                  this.debounce = setTimeout(() => {
                      this.getLoyaltyActivities();
                  }, 1500)
              },
              async getLoyaltyActivities() {
                  this.loading = true;
                  let url = `${this.dashBaseUrl}/loyalty-activities`;
                  url = new URL(url);
                  url.searchParams.set('page', this.page);
                  url.searchParams.set('size', this.recordsPerPage);
                  url.searchParams.set('merchant_id', this.merchantID);
                  if(this.search !=''){
                    url.searchParams.set('search_param', this.search);
                  }
                  if (this.start !== '') {
                      this.start = moment(this.start).format('YYYY-MM-DD');
                      url.searchParams.set('start_date', this.start);
                  }
                  if (this.end !== '') {
                      this.end = moment(this.end).format('YYYY-MM-DD')
                      url.searchParams.set('end_date', this.end);
                  }
                  const config = {
                      headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
                  };
                  this.axios.get(url, config).then((response) => {
                      if (response.status === 200) {
                          this.activities = response.data.data.activites.data;
                          if(this.activities.length > 0){
                            this.summary = response.data.data.summary[0];
                          }
                          this.totalPages = Math.ceil(response.data.data.activites.total / this.recordsPerPage)
                          this.totalRecords = response.data.data.activites.total_count
                      }
                  }).catch((error) => {
                      if (error.response.status === 401) {
                          this.$router.push({
                              name: 'login'
                          });
                      }
                  }).finally(() => {
                      this.loading = false;
                      this.searchLoading = '';
                  })
              },
              async downloadExcel(){
                this.downloading = true;
                let url = `${this.dashBaseUrl}/loyalty-activities`;
                url = new URL(url);
                url.searchParams.set('merchant_id', this.merchantID);
                if(this.search !=''){
                    url.searchParams.set('search_param', this.search);
                }
                if (this.start !== '') {
                    this.start = moment(this.start).format('YYYY-MM-DD');
                    url.searchParams.set('start_date', this.start);
                }
                if (this.end !== '') {
                    this.end = moment(this.end).format('YYYY-MM-DD')
                    url.searchParams.set('end_date', this.end);
                }
                const config = {
                    headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
                };
                this.axios.get(url, config).then((response) => {
                      if (response.status === 200) {
                        const activities = response.data.data.activites;
                        const activityArray = [];

                        let TotalamountEarned = 0;
                        let TotalamountRedeemed = 0;
                        let totalPointEarned = 0;
                        let totalPointRedeemed = 0;
                        let totalPointBalance = 0;
                        for (const activity of Object.values(activities)) {
                          totalPointRedeemed += activity.total_point_redeemed;
                          totalPointEarned +=  activity.total_point_earned;
                          TotalamountEarned +=activity.total_amount_earned;
                          TotalamountRedeemed +=activity.total_amount_redeemed;
                          totalPointBalance +=activity.points;
                                activityArray.push({
                                  'Customer':activity.phone_number,
                                  'Points Earned': activity.total_point_earned,
                                  'Points Redeemed': activity.total_point_redeemed,
                                  'Balance': activity.points,
                                  'Amount Earned': this.formatPrice(activity.total_amount_earned),
                                  'Amount Redeemed': this.formatPrice(activity.total_amount_redeemed)
                                })
                            }
                            activityArray.push({
                              'Customer':'',
                              'Points Earned':totalPointEarned,
                              'Points Redeemed':totalPointRedeemed,
                              'Balance':totalPointBalance,
                              'Amount Earned':TotalamountEarned,
                              'Amount Redeemed': TotalamountRedeemed
                            })
                            const fileName = Date.now() + 'loyalty_activies'
                            this.exportExcel(activityArray, fileName)
                      }
                  }).catch((error) => {
                    this.downloading = false;
                      if (error.response.status === 401) {
                          this.$router.push({
                              name: 'login'
                          });
                      }
                  }).finally(() => {
                      this.downloading = false;
                  })

              },
              onPageChange(page) {
                  this.page = page
                  this.getLoyaltyActivities();
              },
              onChangeRecordsPerPage() {
                  this.getLoyaltyActivities();
              },
          },
          mixins: [ReusableFunctionMixins, ReusableDataMixins]
      }
      </script>
      